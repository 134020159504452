<template>
  <div class="container">
    <div class="grid justify-content-center">
      <div class="col-12 md:col-8 comp-grid">
        <div class="card">
          <Button icon="pi pi-arrow-left" class="p-button-sm p-button-rounded px-4" :label="$t('back_to_home')"
            @click="() => { $router.go(-1); }" />
          <Divider></Divider>
          <div class="text-xl font-bold text-center  mb-3">Verify Phone Number</div>
          <div class="grid justify-content-center">
            <div class="col-12 comp-grid">
              <div class="text-muted text-sm text-center mb-2">
                <img class="" src="images/payment_methods.png" alt="logo" style="width: 70%;" />
              </div>
              <div class="card mb-0 nice-shadow-6">
                <div>
                  <form ref="observer" tag="form" @submit.prevent="startPurchase()" @reset="resetForm">
                    <div class="grid">
                      <div class="col-12">

                        <div class="text-muted text-mg  mb-2 font-bold px-2">
                          Enter the One Time Password sent to you via SMS.
                        </div>
                        <div class="grid">
                          <div class="col-12">
                            <InputText class="w-full text-center otp-input" placeholder="Enter Code Here"
                              v-model="formData.otpCode" required="required" type="text" />
                          </div>
                          <div class="w-full text-center mt-3">
                            <div class="text-2xl font-bold text-info">{{ countDown }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <transition appear enter-active-class="animated bounceIn" v-if="errorMsg"
                      leave-active-class="animated fadeOut">
                      <Message severity="error" :closable="false" @close="errorMsg = ''">{{ errorMsg.toString() }}
                      </Message>
                    </transition>
                    <div class="">
                      <Button :disabled="canResend" class="p-button-lg p-button-raised w-full" :loading="saving"
                        type="submit" label="Verify" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { PageMixin } from "../../mixins/page.js";
export default {
  props: {},
  mixins: [PageMixin],
  data: function () {
    return {
      formData: {
        otpCode: "",
        token: this.$route.query.token,
        msisdn: this.$route.query.msisdn,
      },
      saving: false,
      error: "",
      sentMsg: "",
      countDown: '00:00',
    };
  },
  methods: {
    async startPurchase() {
      this.saving = true;
      this.$api.post("auth/purchaseform", this.formData).then(
        (response) => {
          this.saving = false;
          let resData = response.data;
          if (resData.nextpage) {
            this.$router.push(resData.nextpage);
          }
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
          this.sentMsg = "";
        }
      );
    },

    startCountDown() {
      let duration = parseInt(this.$route.query.duration) || 5; // in minutes
      let minutes = 60 * duration
      let timer = minutes;
      let seconds;
      var self = this;
      const interval = setInterval(function () {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        self.countDown = minutes + ":" + seconds;
        if (--timer < 0) {
          clearInterval(interval);
          self.sentMsg = null,
            self.errorMsg = "OTP has expired";
            self.goback()
        }
      }, 1000);

      
    },
    goback(){
        this.$router.go(-1)
      }
  },
  mounted() {
			this.startCountDown();
		},
};
</script>
<style scoped>
.otp-input {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  letter-spacing: 10px;
}

.otp-input::placeholder {
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  letter-spacing: 1px;
}
</style>